import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizIvu1', 'quizIvu2', 'quizIvu3'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "activity"
    }}>{`Activity`}</h1>
    <p>{`From the Monica and Dad scenario, which of the statements below is an example of identify, validate or understand?`}</p>
    <p>{`Click on the checkboxes below to find out the answer.`}</p>
    <Paper mdxType="Paper">
  <Quiz id="quizIvu1" singleAnswer={true} question="I know you're upset that you can't go to Tahlia's house today." answers={[{
        name: 'Identify',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Validate. This is an example of Dad validating Monica's feelings, by acknowledging
              and showing empathy for her disappointment about not being able to go to Tahlia's house.
            </p>
          </div>
      }, {
        name: 'Validate',
        correct: true,
        response: <div>
            <p>Correct</p>
            <p>
              This is an example of Dad validating Monica's feelings, by acknowledging and showing empathy for her
              disappointment about not being able to go to Tahlia's house.
            </p>
          </div>
      }, {
        name: 'Understand',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Validate. This is an example of Dad validating Monica's feelings, by acknowledging
              and showing empathy for her disappointment about not being able to go to Tahlia's house.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizIvu2" singleAnswer={true} question="I can tell something's upset you today?" answers={[{
        name: 'Identify',
        correct: true,
        response: <div>
            <p>Correct</p>
            <p>
              This is an example of Dad identifying and labelling Monica's emotion, by saying that he can see Monica is
              upset about something.
            </p>
          </div>
      }, {
        name: 'Validate',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Identify. This is an example of Dad identifying and labelling Monica's emotion, by
              saying that he can see Monica is upset about something.
            </p>
          </div>
      }, {
        name: 'Understand',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Identify. This is an example of Dad identifying and labelling Monica's emotion, by
              saying that he can see Monica is upset about something.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizIvu3" singleAnswer={true} question="Did something happen at school today that's making you feel like this?" answers={[{
        name: 'Identify',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Understand. This is an example of Dad exploring what's upsetting Monica, to help him
              and Monica understand the emotion she is experiencing.
            </p>
          </div>
      }, {
        name: 'Validate',
        correct: false,
        response: <div>
            <p>Incorrect</p>
            <p>
              The correct answer is Understand. This is an example of Dad exploring what's upsetting Monica, to help him
              and Monica understand the emotion she is experiencing.
            </p>
          </div>
      }, {
        name: 'Understand',
        correct: true,
        response: <div>
            <p>Correct</p>
            <p>
              This is an example of Dad exploring what's upsetting Monica, to help him and Monica understand the emotion
              she is experiencing.
            </p>
          </div>
      }]} mdxType="Quiz" />
    </Paper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      